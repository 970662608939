<template>
  <div></div>
</template>

<script>
import { Geolocation } from '@capacitor/geolocation'
import { isPlatform } from '@ionic/vue'

export default {
  name: 'MyPosition',
  data () {
    return {
      latModifier: 0.00000000000001,
    }
  },
  props: [
    'myPositionPressed'
  ],
  watch: {
    myPositionPressed () {
      this.myPosition()
    }
  },
  methods: {
    myPosition () {
      if (isPlatform('hybrid')) {
        this.myPositionHybrid()
      } else {
        this.myPositionWeb()
      }
    },

    async myPositionHybrid () {
      this.latModifier = -this.latModifier
      // this.geolocationThinking = true
      this.$emit('sendGeolocationThinking', true)
      const permissions = await Geolocation.checkPermissions()
      if(permissions.location == "denied"){
        alert('Engedélyezd a geolokalizáció használatát az applikáció beállításainál.')
        // this.geolocationThinking = false
        this.$emit('sendGeolocationThinking', false)
      } else {
        try {
          const pos = await Geolocation.getCurrentPosition()
          // const pos = await Geolocation.getCurrentPosition({ enableHighAccuracy: true })
          this.myPositionFound(pos)
        } catch (e) {
          console.log("Geolocation failed", e)
          this.$emit('sendGeolocationThinking', false)
          alert('Hiba történt a geolokalizációval.')
        }
      }
    },

    async myPositionWeb () {
      // this.geolocationThinking = true
      // this.$emit('sendGeolocationThinking', true)
      const pos = await Geolocation.getCurrentPosition()
      this.myPositionFound(pos)
    },

    myPositionFound (pos) {
      this.$emit('sendGeolocationThinking', false)
      this.$emit('myPositionFound', pos)
    }
  }
}
</script>
