<template>
  <map-layout
    :segmentValue="segmentValue"
    @onSegmentChange="onSegmentChange"
  >

    <PanelWinery
      :winery="winery"
      :panelWineryOpenToggle="panelWineryOpenToggle"
      :panelWineryCloseToggle="panelWineryCloseToggle"
      :myLocation="myLocation"
    />
      <!-- @mapRecenter="mapRecenterFunction" -->

    <ion-fab
      :class="fabFilterStyle"
      :vertical="fabFilterPosition"
      horizontal="end"
      slot="fixed"
      :activated="filterModalOpen"
    >
      <ion-fab-button
        v-if="filterDataLoaded"
        color="primary"
        @click="filterModalOpen=!filterModalOpen"
      >
        <ion-icon name="filter"></ion-icon>
      </ion-fab-button>
      <ion-fab-button
        v-else
        color="light"
      >
        <ion-icon name="sync"></ion-icon>
      </ion-fab-button>

    </ion-fab>

    <ion-modal
      :is-open="filterModalOpen"
      css-class="filter-modal"
    >
      <FilterWineryModal
        :wineries="wineries"
        :wineregionsProp="wineregions"
        :wineryprofilesProp="wineryprofiles"
        :winerystylesProp="winerystyles"
        @closedFromModal="closedFromModal"
        @filterResults="filterResults"
        show-backdrop=false
      />
    </ion-modal>

    <!-- v-if="segmentValue == 0 && !isAndroid" -->
    <ion-fab
      v-if="segmentValue == 0"
      vertical="bottom"
      horizontal="end"
      slot="fixed"
      :activated="geolocationThinking"
    >
      <ion-fab-button color="light" @click="myPositionPressed=!myPositionPressed">
        <ion-icon name="navigate"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-slides
      :options="slideOpts"
      @ionSlideDidChange="onSlideDidChange($event)"
      ref="ionSlidesRef"
    >
    <!-- scrollbar="true" -->

      <ion-slide>
        <div class="mapcontainer" lazy>
          <l-map
            :zoom="initialZoom"
            :center="center"
            ref="mymap"
            class="map"
            :options="{zoomControl: false}"
            @ready="showPins()"
          >
          <!-- => cleanupLeafletRender included in showPins, at start-->
          <!-- @ready="cleanupLeafletRender()" -->
          <!-- @update:center="centerUpdated" -->

            <!-- <l-tile-layer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"></l-tile-layer> -->
            <!-- <l-tile-layer url="https://api.mapbox.com/styles/v1/gergohidegfoldi/ckptf6scg0xmv17n34ebz3mf1/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ2VyZ29oaWRlZ2ZvbGRpIiwiYSI6ImNrMzQ3cGhnNDB4OXAzbXA5dzViaTIzZXQifQ._zvL9Ah99V-qEiaiccz0RA"></l-tile-layer> -->

            <l-tile-layer url="https://api.mapbox.com/styles/v1/obt2021/ckz5iigum002s14s5qxiw2v2a/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2J0MjAyMSIsImEiOiJja3AydDV2eWswM3d3MnBsbzRyZGUxdXRwIn0.toLxd_rcor15o3t_t-fy7A"></l-tile-layer>

            <!-- Winery Pins -->
            <div
              v-for="winery in filterSearch"
              :key="winery.id"
            >
              <div
                v-for="address in winery.Addresses"
                :key="address"
              >
                <l-marker
                  @click="openPanelWithWinery(winery, address.x, address.y)"
                  :lat-lng="[address.x, address.y]"
                  :zIndexOffset="winery.zIndexOffset"
                >
                  <l-icon
                    v-if="active==winery.id && activeLat==address.x && activeLng==address.y"
                    :icon-size="[48, 74]"
                    :icon-anchor="[24, 68]"
                    v-bind:iconUrl="bluePin.attributes.src.value"
                  />
                  <l-icon v-else-if="winery.Partner==false"
                    :icon-size="[40, 62]"
                    :icon-anchor="[20, 57]"
                    v-bind:iconUrl="grayPin.attributes.src.value"
                  />
                  <l-icon v-else
                    :icon-size="[40, 62]"
                    :icon-anchor="[20, 57]"
                    v-bind:iconUrl="redPin.attributes.src.value"
                  />
                </l-marker>
              </div>
            </div>

             <!-- Geolocation Pin -->
             <l-marker
                v-if="geolocalized"
               :lat-lng="myLocation"
               :zIndexOffset="10000"
             >
               <l-icon
                 :icon-size="[36, 36]"
                 :icon-anchor="[18, 18]"
                 v-bind:iconUrl="myPin.attributes.src.value"
               />
             </l-marker>
           </l-map>
         </div>
       </ion-slide>

       <ion-slide
        class="listSlide"
        tappable
       >

         <!-- <ion-searchbar
           placeholder="Keresés a borászatok között..."
           inputmode="search"
           animated debounce="500"
           v-model="searchTerm"
         >
         </ion-searchbar> -->

         <WineriesList
           :wineries="filterSearch"
           :myLocation="myLocation"
         />

       </ion-slide>

     </ion-slides>

     <MyPosition
      :myPositionPressed="myPositionPressed"
       @sendGeolocationThinking="sendGeolocationThinking"
       @myPositionFound="myPositionFound"
      />
  </map-layout>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import {
  LMap, LTileLayer, LMarker, LIcon
} from '@vue-leaflet/vue-leaflet'
import {
  IonFab, IonFabButton, IonIcon, IonSlides, IonSlide, IonModal
  // IonSearchbar
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  filter, close, navigate, sync
} from 'ionicons/icons'
import PanelWinery from '@/components/PanelWinery.vue'
import WineriesList from '@/components/WineriesList.vue'
import FilterWineryModal from '@/components/FilterWineryModal.vue'
import MyPosition from '@/components/MyPosition.vue'
import { isPlatform } from '@ionic/vue'
import { mapGetters } from 'vuex'

export default  {
  name: 'CustomMap',
  components: {
    LMap, LTileLayer, LMarker, LIcon,
    IonFab, IonFabButton, IonIcon, IonSlides, IonSlide, IonModal,
    // IonSearchbar,
    PanelWinery, WineriesList, FilterWineryModal, MyPosition
  },
  data () {
    return {
      initialZoom: 6,
      center:{lat: 46.99504803306711, lng: 19.03141752803385},
      wineries: [],
      wineregions: [],
      wineryprofiles: [],
      winerystyles: [],
      active: null,
      activeLat: null,
      activeLng: null,
      activeIndex: null,
      winery: {},
      // panelWineryToggle: false,
      panelWineryOpenToggle: false,
      // panelWineryOpen: false,
      panelWineryCloseToggle: false,
      geolocalized: false,
      myLocation: null,
      slides: null,
      slideOpts: {
        initialSlide: 0,
        speed: 400,
        allowTouchMove: false
      },
      segmentValue: 0,
      redPin: null,
      bluePin: null,
      grayPin: null,
      myPin: null,
      geolocationThinking: false,
      filterModalOpen: false,
      // searchTerm: '',
      regionsArray: [],
      profilesArray: [],
      stylesArray: [],
      filler: null,
      myPositionPressed: false,
      latModifier: 0.00000000000001
    }
  },
  watch: {
    getWineries () {
      if(this.getWineries.length > 0){
        this.wineries = this.getWineries
      }
    },
    getWineregions () {
      if(this.getWineregions.length > 0){
        this.wineregions = this.getWineregions
      }
    },
    getWineryprofiles () {
      if(this.getWineryprofiles.length > 0){
        this.wineryprofiles = this.getWineryprofiles
      }
    },
    getWinerystyles () {
      if(this.getWinerystyles.length > 0){
        this.winerystyles = this.getWinerystyles
      }
    }
  },

  computed: {
    ...mapGetters('wineriesapi', {
      getWineries: 'getWineries',
      getWineregions: 'getWineregions',
      getWineryprofiles: 'getWineryprofiles',
      getWinerystyles: 'getWinerystyles'
    }),
    filterDataLoaded () {
      if (this.wineries.length > 0 && this.wineregions.length > 0 && this.wineryprofiles.length > 0 && this.winerystyles.length > 0) {
        return true
      } else {
        return false
      }
    },
    fabFilterStyle () {
      if (this.segmentValue == 0) {
        return 'fabFilterTop'
      } else {
        return 'fabFilterBottom'
      }
    },
    fabFilterPosition () {
      if (this.segmentValue == 0) {
        return 'top'
      } else {
        return 'bottom'
      }
    },
    filterSearch () {
      return this.wineries
        // filter by term
        // .filter((winery) => {
        //   return winery.Name.toLowerCase().includes(this.searchTerm.toLowerCase())
        // })
        // filter by region
        .filter((winery) => {
          if (this.regionsArray.length > 0) {
            let opt = winery.Wineregions.some((
                { id }) => this.regionsArray.indexOf(id) !== -1)
            return opt
          } else {
            return this.regionsArray
          }
        })
        // filter by profile
        .filter((winery) => {
          if (this.profilesArray.length > 0) {
            let formArray = [winery.WineryProfile] // transform value to array
            let opt = formArray.some((
                { id }) => this.profilesArray.indexOf(id) !== -1)
            return opt
          } else {
            return this.profilesArray
          }
        })
        // filter by style
        // .filter((winery) => {
        //   if (this.stylesArray.length > 0) {
        //     let opt = winery.WineryStyles.some((
        //         { id }) => this.stylesArray.indexOf(id) !== -1)
        //     return opt
        //   } else {
        //     return this.stylesArray
        //   }
        // })
        .filter(winery => {
          if (this.stylesArray.length > 0) {
            let idsArray = []
            for (let i in winery.WineryStyles) {
              idsArray.push(winery.WineryStyles[i].id)
            }
            return this.stylesArray.every(styleId => idsArray.includes(styleId))
          } else {
            return this.stylesArray
          }
        })
    },
    isAndroid () {
      return isPlatform('android')
    }
  },
  created() {
    addIcons({
      'filter': filter,
      'close': close,
      'navigate': navigate,
      'sync': sync
    })
  },
  beforeMount() {
    // this.showPins()
    this.redPin = new Image()
    this.redPin.src = '/assets/images/pin-red.svg'
    this.bluePin = new Image()
    this.bluePin.src = '/assets/images/pin-purple.svg'
    this.grayPin = new Image()
    this.grayPin.src = '/assets/images/pin-gray.svg'
    this.myPin = new Image()
    this.myPin.src = '/assets/images/icon-my-position.png'
  },
  mounted () {
    // this.showPins()
    this.slides = this.$refs.ionSlidesRef.$el
  },
  ionViewDidEnter () {
    this.cleanupLeafletRender()
  },
  ionViewWillLeave () {
    this.panelWineryCloseToggle = !this.panelWineryCloseToggle
    this.active = null
  },
  methods: {
    showPins () {
      this.cleanupLeafletRender()
      this.wineries = this.getWineries
      this.wineregions = this.getWineregions
      this.wineryprofiles = this.getWineryprofiles
      this.winerystyles = this.getWinerystyles
    },
    // async showPins () {
    //   const response = await axios.get(process.env.VUE_APP_STRAPI_URI + 'wineries?_sort=NameWithoutAccents:ASC&_limit=400')
    //   let wineriesCrude = await response.data
    //
    //   // only display wineries that have GPS coordinates
    //   for (let i=0;rkep i<wineriesCrude.length; i++) {
    //     if(wineriesCrude[i].Addresses && wineriesCrude[i].Addresses.length > 0) {
    //       if(wineriesCrude[i].Addresses[0].x && wineriesCrude[i].Addresses[0].y) {
    //         // delete wineriesCrude[i].Logo
    //         wineriesCrude[i].zIndexOffset = 0
    //         this.wineries.push(wineriesCrude[i])
    //       }
    //     }
    //   }
    // },

    filterResults (payload) {
      this.regionsArray = payload.regionsArray
      this.profilesArray = payload.profilesArray
      this.stylesArray = payload.stylesArray
    },

    closedFromModal () {
      this.filterModalOpen = false
      this.panelWineryCloseToggle = !this.panelWineryCloseToggle
      this.active = null
    },

    openPanelWithWinery (winery, lat, lng) {
      this.panelWineryOpenToggle = !this.panelWineryOpenToggle
      // this.panelWineryOpen = true
      this.winery = winery
      this.active = winery.id
      // ensure only one pin will light up, not all different addresses that belong to the same winery
      this.activeLat = lat
      this.activeLng = lng
      //reinitialize previous one
      if (this.activeIndex) {
        this.wineries[this.activeIndex].zIndexOffset = 0
      }
      //set new one
      this.activeIndex = this.wineries.findIndex(x => x.id === winery.id)
      this.wineries[this.activeIndex].zIndexOffset = 1000
    },

    // HERE was myPosition
    sendGeolocationThinking (payload) {
      this.geolocationThinking = payload // true or false
    },
    myPositionFound (pos) {
      this.center = {lat: pos.coords.latitude + this.latModifier, lng: pos.coords.longitude}
      this.myLocation = {lat: pos.coords.latitude, lng: pos.coords.longitude}
      this.initialZoom = 11
      this.geolocalized = true
      this.panelWineryCloseToggle = !this.panelWineryCloseToggle
      this.active = null
      this.geolocationThinking = false
    },

    cleanupLeafletRender () {
      if (this.$refs.mymap && this.$refs.mymap.leafletObject && this.$refs.mymap.leafletObject.options){
        let sizeInvalidatedResp = this.$refs.mymap.leafletObject.invalidateSize()
        if(sizeInvalidatedResp._size.x == 0){
          setTimeout(() => {
            this.cleanupLeafletRender()
          }, 10)
        }
      }
    },

    onSegmentChange (ev) {
      this.slideTo(ev.detail.value)
    },

    slideTo (index) {
      if (this.slides) {
        if (index == 1) {
          this.panelWineryCloseToggle = !this.panelWineryCloseToggle
          this.active = null
        }
        this.slides.slideTo(index)
      }
    },

    async onSlideDidChange (ev) {
      this.filler = ev.isTrusted // otherwise EsLint error
      let index = await this.slides.getActiveIndex()
      this.segmentValue = index // sending it to MapLayout
    }
  }
}
</script>

<style scoped>
.mapcontainer{
  width: 100vw;
  height: 100vh;
}
.mapcontainer img {
  max-height: none;
}
.map{
  width: 100vw;
  height: 100vh;
  background: #faf5e6;
}
ion-fab{
  z-index: 420 !important;
  /* margin-bottom: 70px; */
}
.fabFilterTop{
  margin-top: calc(50px + env(safe-area-inset-top));
}

/* fix default ion-slide settings */
.swiper-slide{
  /* replaced 'flex' */
  display: inline !important;
}
.swiper-zoom-container{
  /* replaced 'flex', 'box' */
  display: inline !important;
  /* display: webkit inherit !important; */
  /* replace 'center' */
  justify-content: inherit !important;
  -webkit-justify-content: inherit !important;
}
.listSlide{
  padding-top: calc(60px + env(safe-area-inset-top));
}
/* ion-searchbar{
  --box-shadow: 0px 0px 2px 0px #CDBAB4;
  --border-radius: 10px;
  text-align: left;
} */
</style>
