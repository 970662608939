<template>
  <div>
    <div
      v-for="winery in wineries"
      :key="winery.id"
      class="winery-section"
    >
      <WineryCardList
        :winery="winery"
        :myLocation="myLocation"
      />
    </div>
  </div>
</template>

<script>
import WineryCardList from '@/components/WineryCardList.vue'

export default {
  name: 'WineriesList',
  props: [
    'wineries',
    'myLocation'
  ],
  components: {
    WineryCardList
  }
}
</script>

<style scoped>
.winery-section{
  padding: 0;
  border-bottom: 1px solid #cccccc;
  width: 100%;
}
</style>
