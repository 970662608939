<template>
  <ion-item
    lines="none"
    class="realTransparent"
    button
    @click="goToWineryDetails()"
    detail="false"
  >

    <ion-thumbnail slot="start">
      <div class="circleWhite">
          <span class="helper"></span>
          <img v-if="winery.Logo && winery.Partner" :src="winery.Logo.url" class="circleLogo"/>
          <img v-else-if="!winery.Partner" :src="'assets/images/logo-not-partner.png'" class="circleLogo"/>
      </div>
    </ion-thumbnail>
    <ion-label>
      <h3>{{ winery.Name }}</h3>
      <h4>
        <span
          class="regionTitle"
          v-for="region in winery.Wineregions"
          :key="region.id"
        >
          {{ region.Title }}
        </span>
        <span
          v-if="myLocation"
          class="separator"
        >
          •
        </span>
        <span
          v-if="myLocation"
          class="location-tag"
        >
          {{ distanceWinery }} km
        </span>
      </h4>
      <h4 v-if="winery.WineryProfile">
        <span
          :class="winery.WineryProfile.Title"
        >
          {{ winery.WineryProfile.Title }}
        </span>
      </h4>
    </ion-label>

  </ion-item>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  IonItem, IonThumbnail, IonLabel, loadingController
} from '@ionic/vue'
import { Dialog } from '@capacitor/dialog'

export default {
  name: 'WineryCardList',
  props: [
    'winery',
    'myLocation'
  ],
  components: {
    IonItem, IonThumbnail, IonLabel
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    }),
    // // Old distance calculation:
    // distanceWinery () {
    //   if(this.winery.Addresses && this.winery.Addresses.length > 0){
    //     let dist = this.distance(this.myLocation.lat, this.myLocation.lng, this.winery.Addresses[0].x, this.winery.Addresses[0].y, 'K')
    //     return Math.round(dist*100)/100 + ' km'
    //   } else {
    //     return 'ismeretlen'
    //   }
    // New distance calculation:
    distanceWinery () {
      if(this.winery.Addresses && this.winery.Addresses.length > 0){
        let distances = []
        for (let i in this.winery.Addresses) {
          distances.push(this.distance(this.myLocation.lat, this.myLocation.lng, this.winery.Addresses[i].x, this.winery.Addresses[i].y, 'K'))
        }
        distances.sort(function(a, b){return a - b})
        let dist = distances[0]
        return Math.round(dist*100)/100
      } else {
        return 'ismeretlen'
      }
    }
  },
  methods: {
    goToWineryDetails () {
      if (this.winery.Partner) {
        this.$router.push({
          name: 'boraszat-profil',
          params: {
            id: this.winery.id
          }
        })
      } else {
        this.showRecommendationDialog()
      }
    },

    async showRecommendationDialog () {
      const { value } = await Dialog.confirm({
        title: 'Partnerborászatnak ajánlom!',
        message: 'Szeretném, ha a borászat csatlakozna az Országos Bortúra partnerpincéi közé.',
        okButtonTitle: 'Legyen partner!',
        cancelButtonTitle: 'Mégsem'
      })
      if (value) {
        this.sendRecommendation()
      }
    },

    async sendRecommendation () {
      if (this.authData && this.authData.userId && this.authData.token) {
        this.presentLoading()
        await axios.post(process.env.VUE_APP_STRAPI_URI + 'partnership-recommendations',
          {
            User: this.authData.userId,
            WineryInSystem: this.winery.id
          },
          {
            headers: {
              Authorization:
              'Bearer ' + this.authData.token
            }
          }
        )
        await this.loading.dismiss()
        alert('Köszönjük az ajánlásodat!')
      } else {
        this.showLoginDialog()
      }
    },

    async showLoginDialog () {
      const { value } = await Dialog.confirm({
        title: 'Logged out',
        message: `You need to be logged in to recommend winery.`,
        okButtonTitle: 'Login',
        cancelButtonTitle: 'Close'
      })
      if (value) {
        this.$router.push('/login')
      }
    },

    distance (lat1, lon1, lat2, lon2, unit) {
      if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0
      }
      else {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
        if (dist > 1) {
          dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit=="K") { dist = dist * 1.609344 }
        return dist
      }
    },

    async presentLoading() {
      this.loading = await loadingController
        .create({
          spinner: 'lines'
        })
      await this.loading.present()
    }
  }
}
</script>

<style scoped>
ion-item.realTransparent{
  --background:transparent !important;
}
h3{
  font-family: Alphabet;
  font-size: 21px !important;
  line-height: 27px;
}
ion-thumbnail{
  margin-top: 25px;
  margin-bottom: 25px;
}
</style>
