<template>
  <ion-content>

    <ion-fab
      class="close-button"
      vertical="top"
      horizontal="end"
      @click="$emit('closedFromModal')"
      slot="fixed"
    >
      <ion-fab-button color="light">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <h1>Szűrő</h1>

    <ion-list
      mode="ios"
    >
      <ion-item
        @click="regionsOpen=!regionsOpen"
        button
        lines="none"
        detail=false
        class="list-title"
      >
        <span>Borvidékek</span>&nbsp;({{ regionsArray.length }})
        <ion-icon
          :name="caretRegions"
          slot="end"
        />
      </ion-item>
      <ion-item-group v-if="regionsOpen==true">
        <ion-item
          v-for="region in wineregions"
          :key="region.id"
          lines="none"
          class="list-names"
        >
          <ion-label>{{region.Title}}</ion-label>
          <ion-checkbox
            slot="start"
            v-model="region.checked"
          >
          </ion-checkbox>
        </ion-item>
      </ion-item-group>
    </ion-list>
    <div class="separator"></div>

    <ion-list
      mode="ios"
    >
      <ion-item
        @click="profilesOpen=!profilesOpen"
        button
        lines="none"
        detail=false
        class="list-title"
      >
        <span>Kategóriák</span>&nbsp;({{ profilesArray.length }})
        <ion-icon
          :name="caretProfiles"
          slot="end"
        />
      </ion-item>
      <ion-item-group v-if="profilesOpen==true">
        <ion-item
          v-for="profile in wineryprofiles"
          :key="profile.id"
          lines="none"
          class="list-names"
        >
          <ion-label>{{profile.Title}}</ion-label>
          <ion-checkbox
            slot="start"
            v-model="profile.checked"
          >
          </ion-checkbox>
          <ion-badge :class="profile.TooltipColor" slot="end">{{ profile.Definition }}</ion-badge>
        </ion-item>
      </ion-item-group>
    </ion-list>
    <div class="separator"></div>

    <ion-list
      mode="ios"
      class="last-list"
    >
      <ion-item
        @click="stylesOpen=!stylesOpen"
        button
        lines="none"
        detail=false
        class="list-title"
      >
        <span>Borstílusok</span>&nbsp;({{ stylesArrayLength }})
        <ion-icon
          :name="caretStyles"
          slot="end"
        />
      </ion-item>
      <ion-item-group v-if="stylesOpen==true">
        <ion-item
          v-for="style in winerystyles"
          :key="style.id"
          lines="none"
          class="list-names"
        >
          <ion-label>{{style.Title}}</ion-label>
          <ion-checkbox
            slot="start"
            v-model="style.checked"
          >
          </ion-checkbox>
        </ion-item>
      </ion-item-group>
    </ion-list>

    <div
      class="button-container"
      slot="fixed"
      vertical="bottom"
      horizontal="end"
    >
      <ion-button
        shape="round"
        fill="transparent"
        @click="emptyFilters()"
      >
        <span class="secondary">Szűrők törlése</span>
      </ion-button>
      <ion-button
        shape="round"
        color="primary"
        @click="applyFilters()"
      >
        Mutasd ({{ filterNumber }})
      </ion-button>
    </div>

  </ion-content>
</template>

<script>
import {
  IonContent, IonFab, IonFabButton, IonIcon,
  IonList, IonItem, IonItemGroup, IonLabel, IonCheckbox, IonButton, IonBadge
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  caretDownOutline, caretBackOutline
} from 'ionicons/icons'

export default {
  name: 'FilterWineryModal',
  components: {
    IonContent, IonFab, IonFabButton, IonIcon,
    IonList, IonItem, IonItemGroup, IonLabel, IonCheckbox, IonButton, IonBadge
  },
  props: [
    'wineries',
    'wineregionsProp',
    'wineryprofilesProp',
    'winerystylesProp'
  ],
  data () {
    return {
      regionsOpen: false,
      profilesOpen: false,
      stylesOpen: false
    }
  },
  computed: {
    wineregions () {
      return this.wineregionsProp
    },
    wineryprofiles () {
      return this.wineryprofilesProp
    },
    winerystyles () {
      return this.winerystylesProp
    },

    regionsArray () {
      return this.arrayFunction(this.wineregions)
    },
    profilesArray () {
      return this.arrayFunction(this.wineryprofiles)
    },
    stylesArray () {
      return this.arrayFunctionStyles(this.winerystyles)
    },
    stylesArrayLength () {
      if (this.stylesArray.length == 0) {
        return this.winerystyles.length
      } else {
        return this.stylesArray.length
      }
    },

    caretRegions () {
      return this.caretFunction(this.regionsOpen)
    },
    caretProfiles () {
      return this.caretFunction(this.profilesOpen)
    },
    caretStyles () {
      return this.caretFunction(this.stylesOpen)
    },

    filterSearch () {
      return this.wineries
        // filterByRegion
        .filter((winery) => {
          let opt = winery.Wineregions.some((
            // { id }) => this.regionsArray.indexOf(id) !== -1)
            { id }) => this.regionsArray.includes(id))
          return opt
        })
        // filterByProfile
        .filter((winery) => {
          let formArray = [winery.WineryProfile]
          let opt = formArray.some((
            { id }) => this.profilesArray.includes(id))
          return opt
        })
        // filterByStyle
        // .filter((winery) => {
        //   if (this.stylesArray.length > 0) {
        //     let opt = winery.WineryStyles.some((
        //         { id }) => this.stylesArray.indexOf(id) !== -1)
        //     return opt
        //   } else {
        //     return this.stylesArray
        //   }
        // })
        .filter(winery => {
          if (this.stylesArray.length > 0) {
            let idsArray = []
            for (let i in winery.WineryStyles) {
              idsArray.push(winery.WineryStyles[i].id)
            }
            return this.stylesArray.every(styleId => idsArray.includes(styleId))
          } else {
            return this.stylesArray
          }
        })
    },

    filterNumber () {
      return this.filterSearch.length
    }
  },
  created () {
    addIcons({
      'caret-back-outline': caretBackOutline,
      'caret-down-outline': caretDownOutline,
    })
  },
  methods: {
    arrayFunction (initialArray) {
      var list = initialArray
        .filter((style) => {
          return style.checked === true
        })
      let newArray = []
      if (list.length > 0) {
        for (let i in list) {
          newArray.push(list[i].id)
        }
      } else {
        for (let i in initialArray) {
          newArray.push(initialArray[i].id)
        }
        // for IOS bug with 'null' added as last   item
        if(newArray.length > initialArray.length){
          newArray.pop()
        }
      }
      return newArray
    },

    arrayFunctionStyles (initialArray) {
      var list = initialArray
        .filter((style) => {
          return style.checked === true
        })
      let newArray = []
      if (list.length > 0) {
        for (let i in list) {
          newArray.push(list[i].id)
        }
      }
      return newArray
    },

    caretFunction (open) {
      if (open) {
        return 'caret-down-outline'
      } else {
        return 'caret-back-outline'
      }
    },

    applyFilters () {
      this.$emit('filterResults', {
        regionsArray: this.regionsArray,
        profilesArray: this.profilesArray,
        stylesArray: this.stylesArray
      })
      this.$emit('closedFromModal')
    },

    emptyFilters () {
      for (let i in this.wineregions) {
        if(this.wineregions[i].checked){
          this.wineregions[i].checked = false
        }
      }
      for (let i in this.wineryprofiles) {
        if(this.wineryprofiles[i].checked){
          this.wineryprofiles[i].checked = false
        }
      }
      for (let i in this.winerystyles) {
        if(this.winerystyles[i].checked){
          this.winerystyles[i].checked = false
        }
      }
    }
  }
}
</script>

<style scoped>
ion-content{
  --background: rgba(255,255,255,1);
  --padding-top: calc(30px + env(safe-area-inset-top));
  --padding-bottom: calc(20px + env(safe-area-inset-bottom));
}
.close-button{
  margin-top: calc(10px + env(safe-area-inset-top));
}
h1{
  text-align: left;
  margin: 20px 20px 25px 20px;
}
.list-title span{
  color: var(--ion-color-darkpurple);
  font-family: Alphabet;
  font-size: 18px;
}
.list-title ion-icon{
  color: var(--ion-color-darkpurple);
}
.list-names{
  font-size: 15px;
}
.button-container{
  margin: 0px 20px;
  /* position: fixed; */
  bottom: calc(20px + env(safe-area-inset-bottom));
  z-index: 1000;
}
.button-container ion-button{
  width: 50%;
  margin:0px;
}
span.secondary{
  color: var(--ion-color-danger)
}
.separator{
  margin: 0 20px;
  border-bottom: 1px solid #eed8cd;
}
ion-item{
  --padding-top: 11px;
  --padding-bottom: 11px;
  --padding-start: 20px;
  --padding-end: 20px;
}
.last-list{
  margin-bottom: calc(70px + env(safe-area-inset-bottom));
}
.CuveeRoze04{
/* .Indie{ */
  background-color: var(--ion-color-secondary);
}
.CuveeRoze03{
/* .Fancy{ */
  background-color: var(--ion-color-danger);
}
.Kekfrankos05{
/* .Family{ */
  background-color: var(--ion-color-primary);
}
</style>
