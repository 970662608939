<template>
  <div class="panelWinery">
    <WineryCardList
      :winery="winery"
      :myLocation="myLocation"
    />
    <div class="panelSpacer"></div>
  </div>
</template>

<script>
import { CupertinoPane } from 'cupertino-pane'
import WineryCardList from '@/components/WineryCardList.vue'

export default {
  name: 'PanelWinery',
  props: [
    'winery',
    'panelWineryOpenToggle',
    'panelWineryCloseToggle',
    'myLocation'
  ],
  components: {
    WineryCardList
  },
  data () {
    return {
      settings: {
        showDraggable: false,
        // handleKeyboard: false,
        fitHeight: true,
        // fitScreenHeight: true,
        maxFitHeight: 600,
        touchMoveStopPropagation: true,
        onTransitionEnd: () => {
          if(this.panelSituation == 'hidden'){
            this.drawerWinery.present({animate: true})
            this.drawerWinery.calcFitHeight()
            this.panelSituation = 'visible'
          }
        },
        onDidDismiss: () => {
          this.panelSituation = 'destroyed'
        }
      },
      panelSituation: 'destroyed', //alt: 'hidden' / 'visible'
      drawerWinery: {}
    }
  },
  mounted () {
    this.drawerWinery = new CupertinoPane('.panelWinery', this.settings)
    this.drawerWinery.disableDrag()
  },
  watch: {
    panelWineryOpenToggle () {
      if (this.panelSituation != 'visible') {
        this.drawerWinery.present({animate: true})
        this.panelSituation = 'visible'
      } else {
        this.drawerWinery.hide({animate: true})
        // this.drawerWinery.destroy({animate: true})
        this.panelSituation = 'hidden'
      }
    },
    panelWineryCloseToggle () {
      if (this.panelSituation && this.panelSituation != 'destroyed') {
        this.panelSituation = 'destroyed'
        this.drawerWinery.hide({animate: true})
        // this.drawerWinery.destroy()
      }
    }
  }
}
</script>

<style scoped>
.panelSpacer{
  height: calc(80px +  env(safe-area-inset-top));
}
</style>
